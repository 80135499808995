/**
* General Styling
**/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* General */

.background {
  background-color: #171725;
  color: rgba(255, 255, 255, 0.7);
}

.background-dark {
  background-color: rgb(14,14,23);
  color: rgba(255, 255, 255, 0.75);
}

.background-black {
  background-color: #171725;
  color: rgba(255, 255, 255, 0.8);
}

.center {
  text-align: center;
}

.vh-100 {
  min-height: 100vh;
}

.color-primary {
  color: #f85102;
}

.color-secondary {
  color: rgb(255, 170, 0);
}

.color-white {
  color: rgba(255, 255, 255, 0.8);
}

.color-grey {
  color: rgba(255, 255, 255, 0.6);
}

.color-green {
  color: #6ba75e
}

.color-error {
  color: #dc3545;
}

.block {
  display: block;
}

.button-primary {
  background-color: #242526;
  padding: 7px 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
}

.button-primary:hover {
  color: rgba(255, 255, 255, 0.9);
}

.pointer:hover {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

/* Sizing */
.w-100 {
  width: 100%;
}

/* Padding */

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 20px;
}

.p-5 {
  padding: 25px;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mb-1 {
  margin-top: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.m-auto {
  margin: auto;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

/* Text */

.link {
  text-decoration: none;
  color: #3498db;
}

.link:hover {
  text-decoration: underline;
}

.link-no-color {
  text-decoration: none;
  color: inherit;
}

.link-no-color:hover {
  cursor: pointer;
  color: #f85102;
  text-decoration: underline;
}

.txt-lg {
  font-size: 3rem;
}

.txt-md {
  font-size: 2rem;
}

.txt-norm {
  font-size: 1.25rem;
}

.txt-sm {
  font-size: 1rem;
  font-weight: 300;
}

.txt-xs {
  font-size: 0.8rem;
}

.uppercase {
  text-transform: uppercase;
}

.weight-lite {
  font-weight: 300;
}

.weight-norm {
  font-weight: 400;
}

.weight-bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

/* Line-height */

.lh-md {
  line-height: 1.7;
}

/* Flex */

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  align-items: center;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

/* Grid */
.grid {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  align-items: center;
  grid-row-gap: 20px;
}

/* Containers */

.container {
  max-width: 1140px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
}

/**
* Media queries
**/

@media (max-width: 1200px) {
  .container {
    max-width: 1140px;
    padding: 0px 20px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 960px;
    padding: 0px 20px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
    padding: 0px 20px;
  }

  .txt-lg {
    font-size: 2rem;
  }

  .txt-md {
    font-size: 1.5rem;
  }

  .txt-norm {
    font-size: 1rem;
  }

  #features .main-feature {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 540px;
    padding: 0px 20px;
  }
}
