#breadcrumbs {
  height: 43px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(14,14,23);
  color: rgba(255, 255, 255, 0.6);
}

#breadcrumbs .breadcrumb {
  position: relative;
  display: inline-block;
  padding: 11px;
  align-items: center;
  margin-right: 10px;
}

#breadcrumbs .breadcrumb::before {
  top: 50%;
  left: 100%;
  width: 0;
  height: 0;
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  border-top: 21px solid transparent;
  margin-top: -21px;
  border-left: 10px solid rgba(0, 0, 0, 0.3);
  margin-left: 1px;
  border-bottom: 21px solid transparent;
}

#breadcrumbs .breadcrumb::after {
  top: 50%;
  left: 100%;
  width: 0;
  height: 0;
  content: '';
  display: block;
  z-index: 3;
  position: absolute;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  margin-top: -21px;
  border-left: 10px solid #171725;
}
