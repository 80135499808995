#players .mc-avatar,
#region .mc-avatar {
  height: 67px;
  width: 67px;
}

#players .ml-80,
#region .player .ml-80 {
  margin-left: 85px;
}

#region .player {
  min-width: 800px;
}

@media (max-width: 900px) {
  #region .player {
    min-width: 100%;
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 500px) {
  #players .ml-80,
  #region .player .ml-80 {
    margin-left: 60px;
  }

  #players .mc-avatar,
  #region .mc-avatar {
    width: 40px;
    height: 40px;
  }
}
